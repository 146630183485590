import React, { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';

const Item = ({ Links, title }) => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ul>
      <h2 className="mb-1 font-semibold text-[#1A5D1A]">{title}</h2>
      {Links.map((link) => (
        <li key={link.name} className="list-none">
          <Link
            className="text-white hover:text-[#BB2525] duration-300
            text-xl cursor-pointer leading-6 no-underline text-bold" 
            to={link.link}
          >
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Item;
